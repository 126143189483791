

















































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class EncuestasList extends Mixins(FormValidator) {
  private nombreWeb: any = "";
  private encuestas: any = [];
  private currentPage = 1;
  private total = 0;
  private mostrar = false;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
      this.getInfoEncuestas();
    }
  }

  private mounted() {
    this.getInfoEncuestas();
  }

  private getInfoEncuestas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("listado/encuestas?pagina=" + this.currentPage)
      .then((res: any) => {
        console.log(res);
        let base = process.env.VUE_APP_BASE_URL;
        this.encuestas = res.data.encuestas;
        for (let i = 0; i < this.encuestas.length; i++) {
          if (this.encuestas[i].foto && this.encuestas[i].foto != "") {
            this.encuestas[i].foto = base + this.encuestas[i].foto;
          } else {
            this.encuestas[i].foto = require("@/assets/logos/logo300.webp");
            this.encuestas[
              i
            ].foto.mobile = require("@/assets/logos/logo300.webp");
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "Ene";
    } else if (mesInicio == 2) {
      mesInicio = "Feb";
    } else if (mesInicio == 3) {
      mesInicio = "Mar";
    } else if (mesInicio == 4) {
      mesInicio = "Apr";
    } else if (mesInicio == 5) {
      mesInicio = "May";
    } else if (mesInicio == 6) {
      mesInicio = "Jun";
    } else if (mesInicio == 7) {
      mesInicio = "Jul";
    } else if (mesInicio == 8) {
      mesInicio = "Ago";
    } else if (mesInicio == 9) {
      mesInicio = "Sep";
    } else if (mesInicio == 10) {
      mesInicio = "Oct";
    } else if (mesInicio == 11) {
      mesInicio = "Nov";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return (
        "Publicado el " + diaInicio + " de " + mesInicio + ", " + anhoInicio
      );
    }
  }

  private irEncuesta(idEncuesta: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Encuesta" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Encuesta",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
