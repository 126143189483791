














































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface respuesta {
  id_pregunta: number | null;
  nombre: string | null;
  obligatorio: number | null;
  tipo: number | null;
  model: any | null;
}
interface SeccionRespuesta {
  id_seccion: number | null;
  preguntas: Array<respuesta>;
}
interface Datos {
  rut: string;
  nombre: string;
  apellido_paterno: string;
  apellido_materno: string;
  direccion: string;
  telefono: string;
  email: string;
  fecha_nacimiento: Date | null;
}
@Component
export default class Encuesta extends Mixins(FormValidator) {
  private encuesta: any = null;
  private step: number = 1;
  private validRut = false;
  private nombreWeb = "";
  private respuestas: Array<SeccionRespuesta> = [];
  private dataPreguntas: Array<respuesta> = [];
  private formattedFecha = "";
  private formattedRut = "";
  private respuestasApi: any = [];
  private disableFinal = false;

  private datosPersonales: Datos = {
    rut: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    direccion: "",
    telefono: "",
    email: "",
    fecha_nacimiento: null
  };
  private cleanRut = "";

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    this.datosPersonales.rut = newValue.replace(/\./g, "");
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        //Usa Chrome u Opera
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        //Usa Safari
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate instanceof Date) {
      this.datosPersonales.fecha_nacimiento = birthDate;
    } else {
      this.datosPersonales.fecha_nacimiento = new Date(birthDate);
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getEncuesta();
      this.linkCanonical(this.nombreWeb);
    } else {
      this.routerGo("Home");
    }
  }

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/encuesta/" + nombreWeb;
    document.head.appendChild(canonicalLink);
  }

  private getEncuesta() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/encuesta?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.encuesta = res.data;
        for (let i = 0; i < this.encuesta.secciones.length; i++) {
          const seccion = this.encuesta.secciones[i];
          this.dataPreguntas = [];
          for (let i = 0; i < seccion.preguntas.length; i++) {
            const pregunta = seccion.preguntas[i];
            let modelRespuesta: any = "";
            if (pregunta.tipo == 0) {
              modelRespuesta = [];
            } else if (pregunta.tipo == 1) {
              modelRespuesta = "";
            } else if (pregunta.tipo == 2) {
              modelRespuesta = "";
            } else if (pregunta.tipo == 3) {
              modelRespuesta = "";
            }
            this.dataPreguntas.push({
              id_pregunta: pregunta.id,
              nombre: pregunta.nombre,
              obligatorio: pregunta.obligatorio,
              tipo: pregunta.tipo,
              model: modelRespuesta
            });
          }
          this.respuestas.push({
            id_seccion: seccion.id,
            preguntas: this.dataPreguntas
          });
        }
        let base = process.env.VUE_APP_BASE_URL;
        if (this.encuesta.foto) {
          this.encuesta.foto = base + this.encuesta.foto;
        }
        if (this.encuesta.foto_mobile) {
          this.encuesta.foto_mobile = base + this.encuesta.foto_mobile;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.routerGo("Home");
      });
  }

  private getStep(index) {
    if (this.encuesta.anonima != 1) {
      return index + 2;
    }
    return index + 1;
  }
  private validarDatosPersonales() {
    if (this.encuesta.anonima != 1) {
      if (
        !this.validateRut(this.datosPersonales.rut) ||
        this.datosPersonales.rut.trim() == ""
      ) {
        Notify.create({
          color: "negative",
          message: "Por favor ingrese su Rut",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      }
      if (this.encuesta.solicita_nacimiento == 1) {
        if (
          this.datosPersonales.fecha_nacimiento == null ||
          !this.checkFecha(this.datosPersonales.fecha_nacimiento)
        ) {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su Fecha de nacimiento",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("safari") != -1) {
          if (!(ua.indexOf("chrome") > -1)) {
            if (this.datosPersonales.fecha_nacimiento.getFullYear() < 1890) {
              Notify.create({
                color: "negative",
                message:
                  "Por favor ingrese su Fecha de nacimiento en un formato válido (DD-MM-AAAA)",
                icon: "report_problem",
                position: "top",
                timeout: 2500
              });
              return;
            }
          }
        }
      }
      if (this.encuesta.solicita_nombre == 1) {
        if (this.datosPersonales.nombre.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su nombre",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (this.datosPersonales.apellido_paterno.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su apellido paterno",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (this.datosPersonales.apellido_materno.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su apellido materno",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }

      if (this.encuesta.solicita_direccion == 1) {
        if (this.datosPersonales.direccion.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su dirección",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
      if (this.encuesta.solicita_telefono == 1) {
        if (this.datosPersonales.telefono.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su teléfono",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (this.datosPersonales.telefono.trim().length < 9) {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un teléfono válido (9 dígitos)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
      if (this.encuesta.solicita_email == 1) {
        if (this.datosPersonales.email.trim() == "") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese su correo",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (!this.validEmail(this.datosPersonales.email)) {
          Notify.create({
            color: "negative",
            message: "Por favor un correo válido",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }
    this.step = 2;
  }
  private validarSeccion(index) {
    if (index < 1) {
      this.respuestasApi = [];
    }
    const seccion = this.respuestas[index];
    for (let i = 0; i < seccion.preguntas.length; i++) {
      const pregunta = seccion.preguntas[i];
      if (pregunta.tipo == 0) {
        if (pregunta.obligatorio == 1 && pregunta.model.length == 0) {
          Notify.create({
            color: "negative",
            message: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (pregunta.model.length > 0) {
          for (let j = 0; j < this.respuestasApi.length; j++) {
            const respuestasAnteriores = this.respuestasApi[j];
            if (respuestasAnteriores.id_pregunta == pregunta.id_pregunta) {
              this.respuestasApi.splice(j, 1);
            }
          }
          for (let j = 0; j < pregunta.model.length; j++) {
            const opcionMarcada = pregunta.model[j];
            let existe = false;
            for (let k = 0; k < this.respuestasApi.length; k++) {
              if (this.respuestasApi[k].id_respuesta == opcionMarcada) {
                existe = true;
              }
            }
            if (!existe) {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: "",
                id_respuesta: opcionMarcada,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 1) {
        if (pregunta.obligatorio == 1 && pregunta.model == "") {
          Notify.create({
            color: "negative",
            message: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = "";
                this.respuestasApi[j].id_respuesta = pregunta.model;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: "",
                id_respuesta: pregunta.model,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 2) {
        if (
          pregunta.obligatorio == 1 &&
          (!pregunta.model || pregunta.model.trim() == "")
        ) {
          Notify.create({
            color: "negative",
            message: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = pregunta.model;
                this.respuestasApi[j].id_respuesta = null;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: pregunta.model,
                id_respuesta: null,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 3) {
        if (
          pregunta.obligatorio == 1 &&
          (!pregunta.model || pregunta.model.trim() == "")
        ) {
          Notify.create({
            color: "negative",
            message: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = pregunta.model;
                this.respuestasApi[j].id_respuesta = null;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: pregunta.model,
                id_respuesta: null,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
    }
    if (index == this.respuestas.length - 1) {
      this.enviarEncuesta();
    } else {
      this.step = this.step + 1;
    }
  }

  private enviarEncuesta() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.disableFinal = true;
    this.$axios
      .post("/responder/encuesta", {
        id: this.encuesta.id,
        datos_personales: this.datosPersonales,
        respuestas: this.respuestasApi
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message:
            "Su encuesta ha sido enviada correctamente, agradecemos su participación.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.routerGo("Home");
      })
      .catch(err => {
        const message = err.response.data.message;
        if (message == "INVALID_DATOS_RUT") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "CONTESTADA") {
          Notify.create({
            color: "negative",
            message: "El Rut ingresado ya ha contestado la encuesta.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_FECHA") {
          Notify.create({
            color: "negative",
            message:
              "Por favor ingrese una fecha de nacimiento válida (DD-MM-AAAA).",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_NOMBRE") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un nombre.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_APELLIDO_PATERNO") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un apellido paterno.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_APELLIDO_MATERNO") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un apellido materno.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_DIRECCION") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese una dirección.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_TELEFONO") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un teléfono.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_DATOS_EMAIL") {
          Notify.create({
            color: "negative",
            message: "Por favor ingrese un correo.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else if (message == "INVALID_PARAM_RESPUESTAS") {
          Notify.create({
            color: "negative",
            message:
              "Debe contestar almenos una pregunta para responder esta encuesta.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
        }
        this.disableFinal = false;
        this.$q.loading.hide();
      });
  }

  private rutSanitize(rut: string) {
    const temp = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = temp.substring(0, temp.length - 1);
    const dv = temp.substring(temp.length - 1);
    return `${rutNumber}-${dv}`;
  }
  private validEmail(email: any) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      this.validRut = false;
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        this.validRut = false;
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      this.validRut = true;
      return true;
    }
    this.validRut = false;
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private checkFecha(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
